import { Link } from "react-router-dom";

const Home = () => {
    return (
        <>
         
  {/* main-header end */}
  {/* Mobile Menu  */}
  <div className="mobile-menu">
    <div className="menu-backdrop" />
    <div className="close-btn">
      <i className="fas fa-times" />
    </div>
    <nav className="menu-box">
      <div className="nav-logo">
        <Link to="/">
          <img src="assets/images/logo-2.png" alt="" title="" />
        </Link>
      </div>
      <div className="menu-outer">
        {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
      </div>
      {/* <div className="contact-info">
        <h4>Contact Info</h4>
        <ul>
          <li>Chicago 12, Melborne City, USA</li>
          <li>
            <a href="tel:+8801682648101">+88 01682648101</a>
          </li>
          <li>
            <a href="mailto:info@example.com">info@example.com</a>
          </li>
        </ul>
      </div> */}
      {/* <div className="social-links">
        <ul className="clearfix">
          <li>
            <a href="index.html">
              <span className="fab fa-twitter" />
            </a>
          </li>
          <li>
            <a href="index.html">
              <span className="fab fa-facebook-square" />
            </a>
          </li>
          <li>
            <a href="index.html">
              <span className="fab fa-pinterest-p" />
            </a>
          </li>
          <li>
            <a href="index.html">
              <span className="fab fa-instagram" />
            </a>
          </li>
          <li>
            <a href="index.html">
              <span className="fab fa-youtube" />
            </a>
          </li>
        </ul>
      </div> */}
    </nav>
  </div>
  {/* End Mobile Menu */}
  {/* banner-section */}
  <section className="banner-section p_relative">
    <div className="auto-container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div data-animation-box="" className="content-box mr_30">
            <span
              data-animation-text=""
              className="sub-title overlay-anim-white-bg"
              data-animation="overlay-animation"
            >
             
            </span>
            <h2>
              About Build  <span>Champions</span> 
            </h2>
            <p>
            Build Champions is dedicated to empowering immigrants, prose litigants, and children through targeted initiatives. Our goal is to foster support, curiosity, and empowerment, making a positive impact on lives and communities
            </p>
            {/* <div className="form-inner">
              <form
                method="post"
                action="https://azim.hostlin.com/Sensai/index.html"
              >
                <div className="form-group">
                  <input
                    type="text"
                    name="text"
                    placeholder="Describe what you want to see"
                    required=""
                  />
                  <button type="submit" className="theme-btn btn-one">
                    Generate
                  </button>
                </div>
              </form>
            </div> */}
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <div className="image-shape">
              <div
                className="shape-1"
                style={{
                  backgroundImage: "url(assets/images/banner/shape-1.png)"
                }}
              />
              <div
                className="shape-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-2.png)"
                }}
              />
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                <figure className="image">
                  <img src="assets/images/banner/img1.webp" alt="" />
                </figure>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                <figure className="image">
                  <img src="assets/images/banner/image 2.webp" alt="" />
                </figure>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                <figure className="image">
                  <img src="assets/images/banner/img3.webp" alt="" />
                </figure>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                <figure className="image">
                  <img src="assets/images/banner/img4.webp" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* banner-section end */}
  {/* clients-section */}
  <section className="clients-section pt_150 pb_150">
    <div className="auto-container">
      <div className="clients-logo-list">
        <figure className="clients-logo">
          <a href="index.html">
            <img src="assets/images/clients/clients-1.png" alt="" />
          </a>
        </figure>
        <figure className="clients-logo">
          <a href="index.html">
            <img src="assets/images/clients/clients-2.png" alt="" />
          </a>
        </figure>
        <figure className="clients-logo">
          <a href="index.html">
            <img src="assets/images/clients/clients-3.png" alt="" />
          </a>
        </figure>
        <figure className="clients-logo">
          <a href="index.html">
            <img src="assets/images/clients/clients-4.png" alt="" />
          </a>
        </figure>
        <figure className="clients-logo">
          <a href="index.html">
            <img src="assets/images/clients/clients-5.png" alt="" />
          </a>
        </figure>
      </div>
    </div>
  </section>
 
  <section className="about-section pb_150">
    <div className="auto-container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
          <div className="image_block_one">
            <div
              data-animation-box=""
              className="image-box pl_50 pt_50 pb_50 mr_40"
            >
              <div
                className="image-shape"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-3.png)"
                }}
              />
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                  <figure
                    data-animation-text=""
                    className="image image-1 overlay-anim-black-bg"
                    data-animation="overlay-animation"
                  >
                    <img src="assets/images/banner/img5.webp" alt="" height={'100px'}  />
                  </figure>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                  <figure
                    data-animation-text=""
                    className="image image-2 overlay-anim-black-bg"
                    data-animation="overlay-animation"
                  >
                   <img src="assets/images/banner/img6.webp" alt="" height={'100px'}  />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content_block_one">
            <div className="content-box ml_40">
              <div data-animation-box="" className="sec-title mb_25">
                <span
                  data-animation-text=""
                  className="sub-title overlay-anim-white-bg"
                  data-animation="overlay-animation"
                >
             
                </span>
                <h2>ONLINE PAYMENTS</h2>
              </div>
              <div className="text-box mb_40">
                <p>
                Make a difference with ease by utilizing our secure online payment system for donations. Supporting our charitable causes has never been more convenient. Whether contributing to empower immigrants, assist prose litigants, or nurture children, our streamlined online payment process ensures a seamless and secure transaction.
                </p>
                <ul className="list-style-one clearfix">
                <li>Empower Immigrants: Support our initiatives aimed at providing resources and assistance to empower immigrants in building successful and fulfilling lives.</li>
  <li>Assist Prose Litigants: Join us in navigating the legal system by contributing to programs that offer support and guidance for individuals facing legal challenges on their own.</li>
  <li>Nurture Children: Make a positive impact on the lives of children through our initiatives that focus on fostering curiosity, education, and overall well-being.</li>
                </ul>
              </div>
              <div className="btn-box">
                <Link to="/" className="theme-btn btn-one">
                  About us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* about-section end */}
  {/* glance-section */}
  <section className="glance-section centred">
    <div className="auto-container">
      <div className="inner-container">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-5.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-6.png)" }}
          />
          <div
            className="pattern-3 rotate-me"
            style={{ backgroundImage: "url(assets/images/shape/shape-7.png)" }}
          />
          <div
            className="pattern-4 rotate-me"
            style={{ backgroundImage: "url(assets/images/shape/shape-8.png)" }}
          />
          <div
            className="pattern-5 rotate-me"
            style={{ backgroundImage: "url(assets/images/shape/shape-9.png)" }}
          />
        </div>
        {/* <div className="sec-title light mb_80">
          <span className="sub-title">AT A GLANCE</span>
          <h2>
            The AI Image Generator at <br />a glance now
          </h2>
          <p>AI isn't here to replace human creativity but it can amplify it</p>
        </div> */}
        {/* <figure className="image-box">
          <img src="assets/images/resource/dashboard-1.jpg" alt="" />
        </figure> */}
      </div>
    </div>
  </section>
  {/* glance-section end */}
  {/* feature-section */}
  <section className="feature-section pt_150 pb_150">
    <div className="auto-container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content-box mr_40">
            <div data-animation-box="" className="sec-title mb_25">
              <span
                data-animation-text=""
                className="sub-title overlay-anim-white-bg"
                data-animation="overlay-animation"
              >
                
              </span>
              <h2>JOIN BUILD CHAMPIONS AND MAKE A DIFFERENCE</h2>
            </div>
            <div className="text-box">
              <p>
              Join Build Champions on our mission to create a positive impact in the world. Your support can make a difference in empowering immigrants, assisting prose litigants, and nurturing children. Together, we can build a brighter future by fostering empowerment, support, and curiosity in communities globally.
              </p>
              <ul className="list-style-one clearfix">
              <li>Global Outreach: Extend your support to our global outreach programs, reaching communities worldwide and making a difference in the lives of diverse populations.</li>
<li>Health and Wellness: Contribute to initiatives promoting health and wellness, ensuring individuals and communities have access to essential resources for a thriving and sustainable future.</li>
<li>Technology for Education: Support the integration of technology into education, helping us create innovative solutions to enhance learning experiences for people of all ages.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
          <div className="image_block_two ml_40">
            <div
              className="image-shape"
              style={{
                backgroundImage: "url(assets/images/shape/shape-10.png)"
              }}
            />
            <div className="image-box">
              <figure className="image image-1">
                <img src="assets/images/banner/img7.webp" alt="" />
              </figure>
              {/* <figure className="image image-2">
                <img src="assets/images/resource/feature-2.jpg" alt="" />
              </figure>
              <figure className="image image-3">
                <img src="assets/images/resource/feature-3.jpg" alt="" />
              </figure>
              <figure className="image image-4">
                <img src="assets/images/resource/feature-4.jpg" alt="" />
              </figure> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* feature-section end */}
  {/* project-section */}
  <section className="project-section pb_150 centred">
    <div className="auto-container">
      <div data-animation-box="" className="sec-title mb_55">
        <span
          data-animation-text=""
          className="sub-title overlay-anim-white-bg"
          data-animation="overlay-animation"
        >
         
        </span>
        <h2>Your Support</h2>
      </div>
      <div className="sortable-masonry">
        <div className="filters mb_50">
          <ul className="filter-tabs filter-btns clearfix">
            {/* <li className="active filter" data-role="button" data-filter=".all">
              Art
            </li>
            <li className="filter" data-role="button" data-filter=".illustrate">
              Illustrate
            </li>
            <li className="filter" data-role="button" data-filter=".anime">
              Anime
            </li>
            <li className="filter" data-role="button" data-filter=".paint">
              Paint
            </li>
            <li className="filter" data-role="button" data-filter=".photo">
              Photo
            </li> */}
          </ul>
        </div>
        <div className="items-container row clearfix">
          <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all paint illustrate anime">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <a
                    href="assets/images/banner/img9.webp"
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <img src="assets/images/banner/img9.webp" alt="" />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all illustrate paint photo">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <a
                    href="assets/images/banner/img10.webp"
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <img src="assets/images/banner/img10.webp" alt="" />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all paint anime">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <a
                    href="assets/images/banner/img11.webp"
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <img src="assets/images/banner/img11.webp" alt="" />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all illustrate anime photo">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <a
                    href="assets/images/banner/img12.webp"
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <img src="assets/images/banner/img12.webp" alt="" />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all paint anime">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <a
                    href="assets/images/banner/img13.webp"
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <img src="assets/images/banner/img13.webp" alt="" />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all illustrate photo">
            <div className="project-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <a
                    href="assets/images/banner/img14.webp"
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <img src="assets/images/banner/img14.webp" alt="" />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="more-btn pt_60">
          <a href="index.html" className="theme-btn btn-one">
          Support Now
          </a>
        </div>
      </div>
    </div>
  </section>
  {/* project-section end */}
  {/* faq-section */}
  {/* <section className="faq-section pb_150">
    <div className="auto-container">
      <div data-animation-box="" className="sec-title centred mb_55">
        <span
          data-animation-text=""
          className="sub-title overlay-anim-white-bg"
          data-animation="overlay-animation"
        >
          General Faq’s
        </span>
        <h2>Frequently Asked Questions</h2>
      </div>
      <div className="inner-box">
        <ul className="accordion-box">
          <li className="accordion block active-block">
            <div className="acc-btn active">
              <div className="icon-box" />
              <h4>What is an AI voice maker?</h4>
            </div>
            <div className="acc-content current">
              <div className="text">
                <p>
                  AI isn't here to replace human creativity but it can amplify
                  it and take it even further. So whether you're creating fanart
                  of your favorite anime. Effortlessly creates and distributes
                  innovative apps across any blockchain, while helping build a
                  more open web
                </p>
              </div>
            </div>
          </li>
          <li className="accordion block">
            <div className="acc-btn">
              <div className="icon-box" />
              <h4>How can I quickly generate realistic voices from texts?</h4>
            </div>
            <div className="acc-content">
              <div className="text">
                <p>
                  AI isn't here to replace human creativity but it can amplify
                  it and take it even further. So whether you're creating fanart
                  of your favorite anime. Effortlessly creates and distributes
                  innovative apps across any blockchain, while helping build a
                  more open web
                </p>
              </div>
            </div>
          </li>
          <li className="accordion block">
            <div className="acc-btn">
              <div className="icon-box" />
              <h4>
                How can I create a TTS video without any prior experience?
              </h4>
            </div>
            <div className="acc-content">
              <div className="text">
                <p>
                  AI isn't here to replace human creativity but it can amplify
                  it and take it even further. So whether you're creating fanart
                  of your favorite anime. Effortlessly creates and distributes
                  innovative apps across any blockchain, while helping build a
                  more open web
                </p>
              </div>
            </div>
          </li>
          <li className="accordion block">
            <div className="acc-btn">
              <div className="icon-box" />
              <h4>How do I generate AI text-to-speech?</h4>
            </div>
            <div className="acc-content">
              <div className="text">
                <p>
                  AI isn't here to replace human creativity but it can amplify
                  it and take it even further. So whether you're creating fanart
                  of your favorite anime. Effortlessly creates and distributes
                  innovative apps across any blockchain, while helping build a
                  more open web
                </p>
              </div>
            </div>
          </li>
          <li className="accordion block">
            <div className="acc-btn">
              <div className="icon-box" />
              <h4>The best platform for listening to audiobooks depends on?</h4>
            </div>
            <div className="acc-content">
              <div className="text">
                <p>
                  AI isn't here to replace human creativity but it can amplify
                  it and take it even further. So whether you're creating fanart
                  of your favorite anime. Effortlessly creates and distributes
                  innovative apps across any blockchain, while helping build a
                  more open web
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section> */}
  {/* faq-section end */}
  {/* testimonial-section */}
  <section className="testimonial-section centred pb_140">
    <div
      className="pattern-layer"
      style={{ backgroundImage: "url(assets/images/shape/shape-11.png)" }}
    />
    <div className="auto-container">
      <div data-animation-box="" className="sec-title mb_55">
        <span
          data-animation-text=""
          className="sub-title overlay-anim-white-bg"
          data-animation="overlay-animation"
        >
          Testimonials
        </span>
        <h2>Love from Users</h2>
      </div>
      <div className="two-item-carousel owl-carousel owl-theme owl-nav-none dots-style-one">
        <div className="testimonial-block-one">
          <div className="inner-box">
            <figure className="thumb-box">
              <img src="assets/images/resource/testimonial-1.png" alt="" />
            </figure>
            <h4>Juelion Amanda</h4>
            <span className="designation">User</span>
            <p>
            "Build Champions has revolutionized the way I create art. It's an incredible tool that sparks my creativity and allows me to express myself in unique ways."
            </p>
          </div>
        </div>
        <div className="testimonial-block-one">
          <div className="inner-box">
            <figure className="thumb-box">
              <img src="assets/images/resource/testimonial-2.png" alt="" />
            </figure>
            <h4>Ronald Rogan</h4>
            <span className="designation">User</span>
            <p>
            Navigating the legal system became so much easier with Build Champions' support. The impact they make in empowering individuals is truly remarkable.
            </p>
          </div>
        </div>
        <div className="testimonial-block-one">
          <div className="inner-box">
            <figure className="thumb-box">
              <img src="assets/images/resource/testimonial-1.png" alt="" />
            </figure>
            <h4>Sarah, Teacher</h4>
            <span className="designation">User</span>
            <p>
            Build Champions is more than just a platform – it's a force for positive change. Being part of their initiatives to nurture children has been incredibly fulfilling, and I'm proud to contribute to shaping a brighter future for the next generation.
            </p>
          </div>
        </div>
        <div className="testimonial-block-one">
          <div className="inner-box">
            <figure className="thumb-box">
              <img src="assets/images/resource/testimonial-2.png" alt="" />
            </figure>
            <h4>Alex, Community Volunteer</h4>
            <span className="designation">User</span>
            <p>
            oining Build Champions was a game-changer for me. Their commitment to supporting immigrants inspired me to contribute, and the impact we create together is truly heartwarming.
            </p>
          </div>
        </div>
       
      </div>
    </div>
  </section>
  {/* testimonial-section end */}
  {/* news-section */}
  <section className="news-section pb_120">
    <div className="auto-container">
      <div data-animation-box="" className="sec-title mb_55 centred">
        <span
          data-animation-text=""
          className="sub-title overlay-anim-white-bg"
          data-animation="overlay-animation"
        >
          Latest News
        </span>
        <h2>Get Latest Media</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-4 col-md-6 col-sm-12 news-block">
          <div
            className="news-block-one"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={700}
          >
            <div className="inner-box">
              <figure className="image-box">
                <a href="blog-details.html">
                  <img src="assets/images/banner/img15.webp" alt="" />
                </a>
              </figure>
              <div className="lower-content">
                <span className="category">Digital Payment</span>
                <h3>
                  <a href="blog-details.html">
                  children's education and development in immigrant 
                  </a>
                </h3>
                <ul className="post-info">
                  <li>
                    <i className="icon-7" />
                    March 20, 2023
                  </li>
                  <li>
                    <i className="icon-8" />
                    <a href="blog-details.html">Alex Beniwal</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 news-block">
          <div
            className="news-block-one"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={900}
          >
            <div className="inner-box">
              <figure className="image-box">
                <a href="blog-details.html">
                  <img src="assets/images/banner/img16.webp" alt="" />
                </a>
              </figure>
              <div className="lower-content">
                <span className="category">Digital Payment</span>
                <h3>
                  <a href="blog-details.html">
                  challenges and triumphs of immigrant life
                  </a>
                </h3>
                <ul className="post-info">
                  <li>
                    <i className="icon-7" />
                    March 18, 2023
                  </li>
                  <li>
                    <i className="icon-8" />
                    <a href="blog-details.html">Haris Gilat</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 news-block">
          <div
            className="news-block-one"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={1100}
          >
            <div className="inner-box">
              <figure className="image-box">
                <a href="blog-details.html">
                  <img src="assets/images/banner/img17.webp" alt="" />
                </a>
              </figure>
              <div className="lower-content">
                <span className="category">Digital Payment</span>
                <h3>
                  <a href="blog-details.html">
                  community support in aiding immigrants
                  </a>
                </h3>
                <ul className="post-info">
                  <li>
                    <i className="icon-7" />
                    March 17, 2023
                  </li>
                  <li>
                    <i className="icon-8" />
                    <a href="blog-details.html">Tomas Wats</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* news-section end */}
  {/* instagram-section */}
  {/* <section className="instagram-section pb_10">
    <div className="outer-container">
      <div className="instagram-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
        <div className="image-box">
          <figure className="image">
            <img src="assets/images/resource/instagram-1.jpg" alt="" />
          </figure>
          <a href="index.html">
            <img src="assets/images/icons/icon-2.png" alt="" />
          </a>
        </div>
        <div className="image-box">
          <figure className="image">
            <img src="assets/images/resource/instagram-2.jpg" alt="" />
          </figure>
          <a href="index.html">
            <img src="assets/images/icons/icon-2.png" alt="" />
          </a>
        </div>
        <div className="image-box">
          <figure className="image">
            <img src="assets/images/resource/instagram-3.jpg" alt="" />
          </figure>
          <a href="index.html">
            <img src="assets/images/icons/icon-2.png" alt="" />
          </a>
        </div>
        <div className="image-box">
          <figure className="image">
            <img src="assets/images/resource/instagram-4.jpg" alt="" />
          </figure>
          <a href="index.html">
            <img src="assets/images/icons/icon-2.png" alt="" />
          </a>
        </div>
        <div className="image-box">
          <figure className="image">
            <img src="assets/images/resource/instagram-5.jpg" alt="" />
          </figure>
          <a href="index.html">
            <img src="assets/images/icons/icon-2.png" alt="" />
          </a>
        </div>
        <div className="image-box">
          <figure className="image">
            <img src="assets/images/resource/instagram-6.jpg" alt="" />
          </figure>
          <a href="index.html">
            <img src="assets/images/icons/icon-2.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </section> */}
  {/* instagram-section end */}
  {/* main-footer */}
 
  {/* main-footer end */}
  {/*Scroll to top*/}
  <div className="scroll-to-top">
    <div>
      <div className="scroll-top-inner">
        <div className="scroll-bar">
          <div className="bar-inner" />
        </div>
        <div className="scroll-bar-text">Go To Top</div>
      </div>
    </div>
  </div>
  {/* Scroll to top end */}


        </>
    );
}

export default Home;