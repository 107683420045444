import { useEffect, useState } from "react";
import ChatGPTFormatter from "./ChatGptFormatter";
import Helpers from "../Config/Helpers";

const Message = ({ message, isWriting = false, isBot = false }) => {
  const [imagePath, setImagePath] = useState("");

  const getImage = () => {
    if (isBot) {
      setImagePath("/app/chat icon 1.png");
    } else {
      let user = Helpers.getItem("user", true);

      if (user) {
        if (user.token && user.profile_pic) {
          setImagePath(Helpers.serverImage(user.profile_pic, user.token));
        } else {
          setImagePath("/app/guest.png");
        }
      } else {
        setImagePath("/app/guest.png");
      }
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <div className="tyn-qa-item">
      <div className="tyn-qa-avatar"></div>
      <div
        className={`tyn-qa-message tyn-text-block ${
          isBot ? "bot-msg" : "human-msg"
        }`}
      >
        {isBot ? (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="tyn-media tyn-size-md m-1 ">
                {imagePath && <img src={imagePath} alt="" />}
              </div>
              <h1 className="bot-name  m-2 text-black">
                {" "}
                <small>Immigration</small>{" "}
              </h1>
            </div>
            <hr></hr>
            <ChatGPTFormatter response={message} writing={isWriting} />
          </>
        ) : (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="tyn-media tyn-size-md ">
                {imagePath && <img src={imagePath} alt="" />}
              </div>
              <h1 className="bot-name m-2 text-black ">
                {" "}
                <small>Me</small>{" "}
              </h1>
            </div>
            <hr></hr>

            <ChatGPTFormatter response={message} writing={isWriting} />
          </>
        )}
      </div>
    </div>
  );
};

export default Message;
