import { createContext, useState } from "react";

export const ChatsContext = createContext();

export const ChatsProvider = ({children}) => {
    const [chats, setChats] = useState([]);
    const [showChat, setShowChat] = useState(false);

    return (
        <ChatsContext.Provider value={{ chats, setChats, showChat, setShowChat }}>
            {children}
        </ChatsContext.Provider>
    )
}