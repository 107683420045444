import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useRef } from "react";

const AskAnything = ({ getReply, message, setMessage, isLoading = false }) => {
    const textareaRef = useRef(null);

    const resizeTextarea = () => {
        if (textareaRef.current && message) {
            if(textareaRef.current.scrollHeight > 40){
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight / 3}px`;
            }
        }else{
            textareaRef.current.style.height = 'auto';
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          getReply();
        }
    };


    const handleInputChange = e => {
        const msg = e.target.value;
        // const words = msg.trim().split(/\s+/).length;
        setMessage(msg)
        // setInputWords(words);
        // if(msg === ""){
        //     setInputWords(0);
        // }
        resizeTextarea();
    }

    const submitForm = e => {
        e.preventDefault();
        getReply();
    }

    useEffect(() => {
        resizeTextarea();
    }, [message]);
    
    return (
        <div className="tyn-chat-form border-0 px-4">
            <div className="container px-0">
                <div className="ps-3 pe-4 py-3 bg-custom mb-5 rounded-3">
                    <form onSubmit={getReply}>
                        <div className="tyn-chat-form-enter">
                            <textarea ref={textareaRef} className="tyn-chat-form-input" rows={1} value={message} onKeyDown={handleKeyPress} onChange={handleInputChange} placeholder="Send a message" style={{ border: 'none',resize: 'none'  }}></textarea>
                            <ul className="tyn-list-inline me-n2 my-1">
                                <li>
                                    <button disabled={!message} onClick={submitForm} className="btn btn-custom">        
                                        {/* <FeatherIcon icon={'send'} /> Send */}
                                        {isLoading ? <>
                                            <i className="fa-duotone fa-spinner-third fa-spin"></i> Writing
                                        </> : <>
                                            <i className="fa-light fa-paper-plane"></i> Send
                                        </>}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AskAnything;