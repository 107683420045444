import { useContext, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import { ChatsContext } from "../../Contexts/ChatsContext";
import axios from "axios";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faHeart } from "@fortawesome/free-solid-svg-icons";

const UserLayout = () => {
  const { chats, setChats, showChat, setShowChat } = useContext(ChatsContext);

  const location = useLocation();

  const deleteChat = (chatId) => {
    axios.get(`${Helpers.apiUrl}chat/delete/${chatId}`).then((response) => {
      Helpers.toast("success", response.data.message);
      setChats(response.data.chats);
    });
  };

  // toggle function
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [showToggleButton, setShowToggleButton] = useState(false);
  const handleResize = () => {
    setShowToggleButton(window.innerWidth <= 768); // Set breakpoint as needed
  };

  useEffect(() => {
    Helpers.toggleCSS();
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]);

  const navigate = useNavigate();

  const gotoChat = (url) => {
    setShowChat(true);
    navigate(url);
  };

  const logout = () => {
    localStorage.clear();

    window.location.href = "/user/dashboard";
    Helpers.toast("success", "Logged out successfully");
  };

  const isLoggedIn = localStorage.getItem("token");

  useEffect(() => {
    // Helpers.loadScript("bundle.js", true)
    //     .then(() => Helpers.loadScript("bundle.js", true))
    //     .catch(() => console.log("Unable to load js"));
  }, []);

  useEffect(() => {
    Helpers.toggleCSS();
  }, [location.pathname]);

  return (
    <div className="tyn-root"  style={{ background: "#0C1B44" }}>
      <nav className="tyn-appbar"  style={{ background: "#0C1B44" }}>
        <div className="tyn-appbar-wrap">
          <div className="tyn-appbar-logo" style={{ position: "relative" }}>
            <Link className="tyn-logo" to={"/"}>
            <img src="/app/imigration white text.png" alt="logo" />
            </Link>
          </div>
          <div
            onClick={() => setShowChat(false)}
            className="nav-icon-container pointer"
          >
            <i class="fa-duotone fa-arrow-left nav-icon"></i>
          </div>
        </div>
      </nav>
      {/* ${showChat ? 'w0' : 'has-aside-base'} */}
      <div
        className={`tyn-content tyn-content-full-height tyn-chatbot tyn-chatbot-page has-aside-base`}
        style={{ background: "#0C1B44" }} >
        <div className="tyn-aside tyn-aside-base"  style={{ background: "#0C1B44" }}>
          <div className="tyn-aside-head">
            <div className="tyn-aside-head-text" >
              <h3 className="tyn-aside-title tyn-title white" >
                Imigration AI {showChat ? "Yes" : "No"}
              </h3>
              <span className="tyn-subtext white-sub">
                {chats.length}+ Conversations{" "}
              </span>
            </div>
            <div className="tyn-aside-head-tools" >
              <ul className="tyn-list-inline gap gap-3">
                <li>
                  <div
                    className="btn btn-icon btn-light btn-md btn-pill btn-newchat pointer"
                    onClick={() => gotoChat("/user/dashboard")}
                    style={{ background: "#0C1B44" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      />
                    </svg>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="tyn-aside-body" data-simplebar   style={{ background: "#0C1B44" }}>
            <ul className="tyn-aside-list">
              {chats.map((chat) => {
                return (
                  <li
                    key={chat.id}
                    className="tyn-aside-item tyn-aside-item-bubbly js-toggle-main chat-item"
                  >
                    <div className="tyn-media-group">
                      <div
                        className="tyn-media-col pointer"
                        onClick={() => gotoChat(`/user/chat/${chat.chatId}`)}
                      >
                        <div className="content white">{chat.title}</div>
                      </div>
                      <div className="tyn-media-option">
                        <ul className="tyn-media-option-list">
                          <li>
                            <button
                              className="btn btn-icon btn-md btn-pill btn-light"
                              onClick={() => deleteChat(chat.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6a.5.5 0 0 0-1 0Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tyn-aside-foot">
            <div className="w-100">
              <div className="row gx-3">
                <div className="col-6">
                  {isLoggedIn ? (
                    <button
                      className="btn btn-dark tyn-size-lg w-100 flex-column py-2 pt-3"
                      onClick={logout}
                      style={{  background : "#c48219" }}
                      
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-box-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 12.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zM.146 8.354a.5.5 0 1 1 .708 0L4 11.5V8a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1H4.707l-3.146 3.146a.5.5 0 1 1-.708-.708L4.293 8.5H.5a.5.5 0 0 1-.5-.5z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M13.5 3A1.5 1.5 0 0 1 15 4.5v7a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 3 11.5v-7A1.5 1.5 0 0 1 4.5 3h9zM4 4.5v7a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"
                        />
                      </svg>
                      <span
                        className="small text-nowrap mt-n1"
                        style={{ color: "black"  }}
                  
                      >
                        Logout
                      </span>
                    </button>
                  ) : (
                    <Link
                      to="/auth/register"
                      className="btn btn-dark tyn-size-lg w-100 flex-column py-2 pt-3"
                      style={{  background : "#c48219" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-person-plus"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        <path
                          fillRule="evenodd"
                          d="M12.5 5.5a.5.5 0 0 1 .5.5v1.5h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H12V5.5a.5.5 0 0 1 .5-.5z"
                        />
                      </svg>
                      <span
                        className="small text-nowrap mt-n1"
                        style={{ textDecoration: "none", color: "black" ,  }}
                      >
                        Create Account
                      </span>
                    </Link>
                  )}
                </div>

                <div className="col-6">
                  <Link
                    to="/auth/Payments"
                    className="btn btn-dark tyn-size-lg w-100 flex-column py-2 pt-3 "
                    style={{ background : "#c48219"  }}
                  >
                    <FontAwesomeIcon icon={faHeart} />
                    <span
                      className="small text-nowrap mt-n1"
                      style={{ textDecoration: "none", color: "black"  }}
                    >
                      Donate
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default UserLayout;
