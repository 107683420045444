import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './App.css';
import Layout from './Screens/Layout';
import Home from './Screens/Home';
import UserLayout from './Screens/User/Layout';
import UserDashboard from './Screens/User/Dashboard';
import { ChatsProvider } from './Contexts/ChatsContext';
import Chatbot from './Screens/User/Chatbot';
import Login from './Screens/Auth/Login';
import Register from './Screens/Auth/Register';
import Payments from  './Screens/Auth/Payments';



// Replace 'your-stripe-public-key' with your actual Stripe public key
const stripePromise = loadStripe('pk_test_51OX45lA0UPMTlR8ll1dXkebkEh5sub11q7TVBOEczInXokdW1exvlZSChEKuXcmqwZ8JAmwPbE8y80O10W4bjSQD00AxkteSoU');

function App() {
  const token = localStorage.getItem('token');

  return (
    <ChatsProvider>
        <Elements stripe={stripePromise}>
      <BrowserRouter>
    
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<Home />} />
           
           
          </Route>
          <Route path='/auth/login' element={<Login />} />
          {token ? (
            <Route path='/auth/register' element={<Navigate to="/user/dashboard" />} />
          ) : (
            <Route path='/auth/register' element={<Register />} />
          )}

          <Route path='/auth/Payments'element={<Payments />}  />
          <Route path='/user' element={<UserLayout />}>
            
            <Route path='/user/dashboard' element={<UserDashboard />} />
            <Route path='/user/chat/:chat_id' element={
              <Elements stripe={stripePromise}>
                <Chatbot />
              </Elements>
            } />
          </Route>
        </Routes>
      </BrowserRouter>
      </Elements>
    </ChatsProvider>
  );
}

export default App;
