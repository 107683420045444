import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import Layout from "../User/Layout";

import Helpers from "../../Config/Helpers";
import { ChatsContext } from "../../Contexts/ChatsContext";
import axios from "axios";

const DonationForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [amount, setAmount] = useState(10);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe has not loaded");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: email,
        },
      });

    if (paymentMethodError) {
      console.error(paymentMethodError);
      return;
    }

    const response = await fetch(`${Helpers.apiUrl}donate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_method_id: paymentMethod.id,
        amount: amount,
        email: email,
      }),
    });

    const result = await response.json();

    if (result.error) {
      console.error(result.error);
      return;
    }

    const { error: confirmError } = await stripe.confirmCardPayment(
      result.clientSecret,
      {
        payment_method: paymentMethod.id,
      }
    );

    if (confirmError) {
      console.error(confirmError);
      return;
    }

    navigate("/user/dashboard");
    Helpers.toast("success", "Thank You for your Donation");
  };

  return (
    <>
      <div className=" tyn-root  " style={{ background: "#F8F8F8" }}>
        <div className="tyn-content tyn-auth tyn-auth-centered">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div class="my-3 text-center">
                  <img src="/app/imigration.png" className="w200" />
                </div>
                <div
                  className="card border-0 card-custom"
                  style={{ background: "#0C1B44" }}
                >
                  <div className="p-4">
                    <h3 className="white text-center">Make a Donation</h3>
                    <form onSubmit={handleSubmit} className="row g-3">
                      <div className="col-12">
                        <div className="form-group">
                          <label
                            htmlFor="amount"
                            className="form-label white-sub"
                          >
                            Donation Amount ($):
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              id="amount"
                              className="form-control custom-input"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                              placeholder="Enter amount"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="form-label white-sub"
                          >
                            Email:
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="email"
                              id="email"
                              className="form-control custom-input"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your email"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label
                            htmlFor="card-details"
                            className="form-label white-sub"
                          >
                            Card Details:
                          </label>
                          <div className="form-control-wrap">
                            <CardElement
                              id="card-details"
                              className="form-control custom-input"
                              options={{
                                style: {
                                  base: {
                                    fontSize: "16px",
                                    color: "white",
                                    "::placeholder": {
                                      color: "white",
                                    },
                                  },
                                  invalid: {
                                    color: "#fa755a",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-custom w-100">
                          Donate
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonationForm;
