import { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Helpers from "../Config/Helpers";

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    Helpers.loadScript("jquery.js")
      .then(() => Helpers.loadScript("bootstrap.min.js"))
      .then(() => Helpers.loadScript("owl.js"))
      .then(() => Helpers.loadScript("wow.js"))
      .then(() => Helpers.loadScript("jquery.fancybox.js"))
      .then(() => Helpers.loadScript("appear.js"))
      .then(() => Helpers.loadScript("isotope.js"))
      .then(() => Helpers.loadScript("jquery.nice-select.min.js"))
      .then(() => Helpers.loadScript("aos.js"))
      .then(() => Helpers.loadScript("plugins.js"))
      .then(() => Helpers.loadScript("script.js"))
      .catch((error) => console.error("Script loading failed: ", error));
  }, []);

  useEffect(() => {
    Helpers.toggleCSS();
  }, [location.pathname]);
  return (
    <div className="boxed_wrapper ltr">
      <div className="loader-wrap">
        <div className="preloader">
          <div className="preloader-close">close</div>
          <div id="handle-preloader" className="handle-preloader">
            <div className="animation-preloader">
              <div className="spinner" />
              <div className="txt-loading">
                <span data-text-preloader=" B" className="letters-loading">
                  B
                </span>
                <span data-text-preloader="U" className="letters-loading">
                  U
                </span>
                <span data-text-preloader="I" className="letters-loading">
                  I
                </span>
                <span data-text-preloader="L" className="letters-loading">
                  L
                </span>
                <span data-text-preloader="D" className="letters-loading">
                  D
                </span>
                <span data-text-preloader="C" className="letters-loading">
                  C{" "}
                </span>

                <span data-text-preloader="H" className="letters-loading">
                  H{" "}
                </span>
                <span data-text-preloader="A" className="letters-loading">
                  A{" "}
                </span>
                <span data-text-preloader="M" className="letters-loading">
                  M{" "}
                </span>
                <span data-text-preloader="P" className="letters-loading">
                  P{" "}
                </span>
                <span data-text-preloader="I" className="letters-loading">
                  I{" "}
                </span>
                <span data-text-preloader="A" className="letters-loading">
                  A{" "}
                </span>
                <span data-text-preloader="N" className="letters-loading">
                  N{" "}
                </span>
                <span data-text-preloader="S" className="letters-loading">
                  S{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* preloader end */}
      {/* page-direction */}
      <div className="page_direction">
        <div className="demo-rtl direction_switch">
          <button className="rtl">RTL</button>
        </div>
        <div className="demo-ltr direction_switch">
          <button className="ltr">LTR</button>
        </div>
      </div>
      {/* page-direction end */}
      {/*Search Popup*/}
      <div id="search-popup" className="search-popup">
        <div className="popup-inner">
          <div className="upper-box clearfix">
            <figure className="logo-box pull-left">
              <Link to="index.html">
                <img src="/app/imigration.png" alt="" width={"300px"} />
              </Link>
         </figure>
            <div className="close-search pull-right">
              <span className="icon-44" />
            </div>
          </div>
          <div className="overlay-layer" />
          <div className="auto-container">
            <div className="search-form">
              <form
                method="post"
                action="https://azim.hostlin.com/Sensai/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      defaultValue=""
                      placeholder="Type your keyword and hit"
                      required=""
                    />
                    <button type="submit">
                      <i className="icon-1" />
                    </button>
                  </fieldset>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* main header */}
      <header className="main-header header-style-one">
        {/* header-lower */}
        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="index.html">
                    <img src="/app/imigration.png" alt="" width={"300px"} />
                  </Link>
             </figure>
              </div>
              <div className="menu-area">
                {/*Mobile Navigation Toggler*/}
                <div className="mobile-nav-toggler">
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <nav className="main-menu navbar-expand-md navbar-light clearfix">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="current dropdown">
                        <Link to="/">Home</Link>
                        <ul>
                          <li>
                            <Link href="/">Image Generate</Link>
                          </li>
                          <li>
                            <Link href="index-2.html">
                              Image Generator Dark{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/">AI Writer</Link>
                       </li>
                          <li>
                            <Link to="/">AI Audio</Link>
                       </li>
                          <li>
                            <Link to="/">AI Video</Link>
                       </li>
                          <li>
                            <Link to="/">AI Chatbot</Link>
                       </li>
                          <li>
                            <Link to="/">AI SAAS</Link>
                       </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="about.html">About</Link>
                   </li>
                      <li className="dropdown">
                        <Link to="#">Pages</Link>
                     <ul>
                          <li className="dropdown">
                            <Link to="#">Our Services</Link>
                         <ul>
                              <li>
                                <Link to="/">Services One</Link>
                           </li>
                              <li>
                                <Link to="/">Services Two</Link>
                           </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">FAQ’s</Link>
                         <ul>
                              <li>
                                <Link to="/">FAQ’s One</Link>
                           </li>
                              <li>
                                <Link to="/">FAQ’s Two</Link>
                           </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Pricing</Link>
                         <ul>
                              <li>
                                <Link to="/">Pricing One</Link>
                           </li>
                              <li>
                                <Link to="/">Pricing Two</Link>
                           </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Testimonials</Link>
                         <ul>
                              <li>
                                <Link to="/">Testimonials One</Link>
                           </li>
                              <li>
                                <Link to="/">
                                  Testimonials Two
                                </Link>
                           </li>
                              <li>
                                <Link to="/">
                                  Testimonials Three
                                </Link>
                           </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Shop</Link>
                         <ul>
                              <li>
                                <Link to="/">Our Shop</Link>
                           </li>
                              <li>
                                <Link to="/">Shop Details</Link>
                           </li>
                              <li>
                                <Link to="/">Cart</Link>
                           </li>
                              <li>
                                <Link to="/">Checkout</Link>
                           </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/">Our Team</Link>
                       </li>
                          <li>
                            <Link to="error.html">404</Link>
                       </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link to="#">News</Link>
                     <ul>
                          <li>
                            <Link to="/">Blog Grid</Link>
                       </li>
                          <li>
                            <Link to="/">Blog Standard</Link>
                       </li>
                          <li>
                            <Link to="/">Blog Details</Link>
                       </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/">Contact Us</Link>
                   </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content">
                <div className="search-box-outer search-toggler mr_30">
                  <i className="icon-1" />
                </div>
                <div className="btn-box">
                  <Link to="/user/dashboard" className="theme-btn btn-one">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*sticky Header*/}
        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="index.html">
                    <img src="/app/imigration.png" alt="" width={"300px"} />
                  </Link>
             </figure>
              </div>
              <div className="menu-area">
                <nav className="main-menu clearfix">
                  {/*Keep This Empty / Menu will come through Javascript*/}
                </nav>
              </div>
              <div className="menu-right-content">
                <div className="search-box-outer search-toggler mr_30">
                  <i className="icon-1" />
                </div>
                <div className="btn-box">
                  <Link to="/user/dashboard" className="theme-btn btn-one">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Outlet />

      <footer className="main-footer">
        <div className="auto-container">
          <div className="widget-section">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget logo-widget">
                  <figure className="footer-logo">
                    <Link to="index.html">
                      <img src="/app/imigration white text.png" alt="" width={"300px"} />
                    </Link>
               </figure>
                  {/* <p>
                    Combined with Link hay platform and top-notch support from
                    our in-house production team
                  </p>
                  <ul className="social-links clearfix">
                    <li>
                      <Link to="index.html">
                        <i className="fa-brands fa-facebook" />
                      </Link>
                 </li>
                    <li>
                      <Link to="index.html">
                        <i className="fa-brands fa-twitter" />
                      </Link>
                 </li>
                    <li>
                      <Link to="index.html">
                        <i className="fa-brands fa-linkedin" />
                      </Link>
                 </li>
                    <li>
                      <Link to="index.html">
                        <i className="fa-brands fa-dribbble" />
                      </Link>
                 </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget links-widget ml_60">
                  <div className="widget-title">
                    <h3>Resources</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li>
                        <Link to="/">About</Link>
                   </li>
                      <li>
                        <Link to="/">Contact</Link>
                   </li>
                      {/* <li>
                        <Link to="blog.html">Blog</Link>
                   </li>
                      <li>
                        <Link to="shop.html">Affiliates</Link>
                   </li>
                      <li>
                        <Link to="contact.html">Help</Link>
                   </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget links-widget">
                  <div className="widget-title">
                    <h3>Primary Pages</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li>
                        <Link to="/">Privacy</Link>
                   </li>
                      {/* <li>
                        <Link to="index.html">Press</Link>
                   </li>
                      <li>
                        <Link to="index.html">Terms &amp; Conditions</Link>
                   </li>
                      <li>
                        <Link to="index.html">Refund Policy</Link>
                   </li>
                      <li>
                        <Link to="index.html">Sitemap</Link>
                   </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget subscribe-widget">
                  <div className="widget-title">
                    <h3>Subscribe Newsletter</h3>
                  </div>
                  <div className="widget-content">
                    <p>
                      Combined with Link hay platform and top-notch support from
                      our in-house production team
                    </p>
                    <div className="form-inner">
                      <form
                        method="post"
                        action="https://azim.hostlin.com/Sensai/contact.html"
                      >
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            required=""
                          />
                          <button type="submit" className="theme-btn btn-one">
                            Subscribe
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom centred">
          <div className="auto-container">
            <div className="copyright">
              <p>
                Copyright © 2023 <Link to="/">immigration</Link>, c. All
                Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>

      <div className="scroll-to-top">
        <div>
          <div className="scroll-top-inner">
            <div className="scroll-bar">
              <div className="bar-inner" />
            </div>
            <div className="scroll-bar-text">Go To Top</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
