import { useState } from "react";
import { Link, json } from "react-router-dom";
import axios from "axios";
import Helpers from "../../Config/Helpers";

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setErrors({});

    axios
      .post(`${Helpers.apiUrl}auth/login`, loginData)
      .then((response) => {
        Helpers.setItem("user", response.data.user, true);
        Helpers.setItem("token", response.data.token);
        Helpers.setItem("id", response.data.user.id);

        if (response.data.token) {
          window.location.href = "/user/dashboard";
        } else {
          window.location.href = "/auth/register";
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.errors || {});
          Helpers.toast("error", error.response.data.message);
        }
      });
  };

  return (
    <div class="tyn-root " style={{ background: "#F8F8F8" }}>
      <div class="tyn-content tyn-auth tyn-auth-centered">
        <div class="container">
          <div class="row justify-content-center">
            <div
              class="col-xl-4 col-lg-5 col-md-7 col-sm-9"
              style={{
                background: "#0C1B44",
                padding: "30px",
                borderRadius: "10px",
              }}
            >
              <div class="my-3 text-center">
                <img src="/app/imigration white text.png" className="w200" />
              </div>
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label
                    htmlFor="email-address"
                    className="form-label white-sub"
                  >
                    Email Address
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control custom-input"
                      id="email-address"
                      name="email"
                      placeholder="youremail@example.com"
                      value={loginData.email}
                      onChange={handleInputChange}
                    />
                    <small className="text-danger">
                      {errors.email ? errors.email[0] : ""}
                    </small>
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="password"
                    className="form-label d-flex white-sub"
                  >
                    Password
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="password"
                      className="form-control custom-input"
                      id="password"
                      name="password"
                      placeholder="password"
                      value={loginData.password}
                      onChange={handleInputChange}
                    />
                    <small className="text-danger">
                      {errors.password ? errors.password[0] : ""}
                    </small>
                  </div>
                </div>

                <div className="form-group pt-2">
                  <button type="submit" className="btn btn-custom w-100">
                    Account Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <p class="small white-sub">
          Don't have an account?{" "}
          <Link className="color-primary" to="/auth/register">
            Create a Free Account
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
