import { useContext, useEffect } from "react";
import Helpers from "../../Config/Helpers";
import axios from "axios";
import { ChatsContext } from "../../Contexts/ChatsContext";
import { useNavigate } from "react-router-dom";

const UserDashboard = () => {
  const { setChats, showChat } = useContext(ChatsContext);
  const navigate = useNavigate();
  const defineUser = () => {
    const user = Helpers.getItem("user", true);
    if (user) {
      Helpers.setItem("id", user.id);
    } else {
      axios.get(`https://api.ipify.org/?format=json`).then((response) => {
        Helpers.setItem("id", response.data.ip);
      });
    }
    getChats();
  };

  const getChats = () => {
    let userId = Helpers.getItem("id");
    axios.get(`${Helpers.apiUrl}chat/all/${userId}`).then((response) => {
      setChats(response.data);
    });
  };

  const initChat = (message = "") => {
    if (message) {
      Helpers.setItem("message", message);
    }
    let chatId = Helpers.generateChatId();
    // window.location.href = `/user/chat/${chatId}`;
    navigate(`/user/chat/${chatId}`);
  };

  useEffect(() => {
    defineUser();
  }, []);

  return (
    <div
      className={`tyn-main ${showChat ? "main-shown" : ""}`}
      id="tynMain"
      style={{ overflow: "auto", backgroundColor: "white" }}
    >
      <div className="tyn-section">
        <div className="container-fluid">
          {/* style={{ backgroundColor: "#1A1A1A" }} */}
          <div
            className="tyn-text-block text-center pb-4 pb-lg-5"
            // style={{ backgroundColor: "#1A1A1A" }}
          >
            <a className="tyn-logo" href="index.html">
              <img src="/app/imigration.png" alt="logo" />
            </a>
            <h1 className="mt-3 black">Welcome Back to Imigration</h1>
            <p className="white-sub  text-dark">Your Imigration Ai.</p>
          </div>
          <div
            className="row g-3 justify-content-center text-center"
            // style={{ backgroundColor: "#1A1A1A" }}
          >
            <div className="col-xl-4 col-lg-4">
              <h4 className="title mb-3 white-sub">Legal Queries</h4>
              <div className="row g-3 justify-content-center">
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "Can you provide recent Supreme Court judgments on copyright law?"
                      )
                    }
                  >
                    <div
                      className="card-body"
                      style={{ background: "#0C1B44" }}
                    >
                      <div
                        className="tyn-text-block"
                        style={{ background: "#0C1B44" }}
                      >
                        <p>
                          What types of consultations does Build Champions offer
                          for immigrants?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "I need information on civil procedure rules in New York for small claims"
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          Is there a fee associated with immigration
                          consultations at Build Champions?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "What are the Federal Rules of Civil Procedure regarding class action lawsuits?"
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          What areas of immigration law does Build Champions
                          provide legal assistance for?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "Explain the criminal procedure rules in California for a felony case."
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          Can Build Champions help with visa applications, and
                          if so, which types?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <h4 className="title mb-3 black">Document Drafting Requests</h4>
              <div className="row g-3 justify-content-center">
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "Draft a complaint for a breach of contract case in Texas."
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          How does Build Champions assist eligible individuals
                          in family reunification?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "Can you create a privacy policy compliant with GDPR for my website?"
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          How does Build Champions support individuals with
                          Temporary Protected Status (TPS)?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "Help me draft a motion for summary judgment in a Florida personal injury case."
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          What topics are covered in the community education
                          sessions conducted by Build Champions?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "I need a post-judgment motion for a family law case in Washington D.C."
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          What forms of humanitarian relief does Build Champions
                          assist immigrants in applying for?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <h4 className="title mb-3 black">Legal Advice</h4>
              <div className="row g-3 justify-content-center">
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "What are my legal rights in a workplace harassment situation?"
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          How often does Build Champions host legal clinics, and
                          what services are provided during these clinics?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "Explain the implications of intellectual property law for small businesses."
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          How does Build Champions contribute to advocacy for
                          immigrant rights?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "Provide legal advice on tenant rights in a landlord dispute in Illinois."
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          In what ways does Build Champions work on policy
                          initiatives related to immigration laws?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-12">
                  <div
                    className="card h-100 border-0 pointer card-custom"
                    style={{ background: "#0C1B44" }}
                    onClick={() =>
                      initChat(
                        "How does bankruptcy law work for individuals in financial distress?"
                      )
                    }
                  >
                    <div className="card-body">
                      <div className="tyn-text-block">
                        <p>
                          How does Build Champions empower immigrants through
                          support and guidance?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <button
              className="btn btn-custom btn-rg btn-pill"
              onClick={() => initChat("")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-send-fill"
                viewBox="0 0 16 16"
              >
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
              </svg>
              <span>Start Discussing</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
